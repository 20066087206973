import { EditorSDK, WidgetInstallationType } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import PlanListWidget from '../components/PlanListWidget/.component.json';
import { compressUuidArray } from '../services/uuid-compression';

const DEFAULT_PRESET_ID = 'variants-lhrjp3681';

export async function addPlanListWidget(params: {
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
  planIds?: string[];
}) {
  const { editorSDK, planIds } = params;
  const refComponent = await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: PlanListWidget.id,
    installationType: WidgetInstallationType.Closed,
    layout: {
      width: 980,
      height: 1080,
      x: 0,
      y: 100,
    },
    scopedPresets: {
      desktop: {
        layout: DEFAULT_PRESET_ID,
        style: DEFAULT_PRESET_ID,
      },
      mobile: {
        layout: DEFAULT_PRESET_ID,
        style: DEFAULT_PRESET_ID,
      },
    },
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        hidden: false,
        height: {
          type: 'auto',
        },
        minHeight: {
          type: 'px',
          value: 500,
        },
        width: {
          type: 'px',
          value: 980,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'center',
        justifySelf: 'center',
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        margins: {
          top: {
            type: 'px',
            value: 0,
          },
          left: {
            type: 'px',
            value: 0,
          },
        },
      },
      containerLayout: {
        type: 'GridContainerLayout',
        columns: [{ type: 'fr', value: 1 }],
        rows: [{ type: 'fr', value: 1 }],
      },
    },
  });
  await editorSDK.application.livePreview.refresh('', {
    shouldFetchData: true,
    source: 'CONNECTED_COMPONENT_ADDED',
  });
  await editorSDK.document.application.appStudioWidgets.props.set('', {
    widgetRef: (await editorSDK.document.components.getChildren('', { componentRef: refComponent }))[0],
    newProps: { planIds: compressUuidArray(planIds ?? []).join(',') },
  });
  return refComponent;
}
